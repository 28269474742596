import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

    constructor() { }

    // public exportAsExcelFile(json: any[], excelFileName: string): void {

    //     const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    //     const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    //     const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });

    //     this.saveAsExcelFile(excelBuffer, excelFileName);
    // }

    public exportAsExcelFile(json: any[], excelFileName: string, array: any[]): void {

        const worksheet = XLSX.utils.json_to_sheet(json);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Data");

        if (array.length > 0) {

            try {
                /* fix headers */
                XLSX.utils.sheet_add_aoa(worksheet, array, { origin: "A1" });
            }
            catch (error) {
                console.error('Here is the error message', error);
            }

        }

        /* calculate column width */
        // const max_width = json.reduce((w, r) => Math.max(w, r.name.length), 10);
        // worksheet["!cols"] = [{ wch: max_width }];
        worksheet["!cols"] = [{ wch: 10 }];
        this.autofitColumns(array, worksheet);
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private autofitColumns(json: any[], worksheet: XLSX.WorkSheet) {

        let objectMaxLength: number[] = [];

        json.map(jsonData => {
            Object.entries(jsonData)
                .map(([, v], idx) => {
                    let columnValue = v as string
                    objectMaxLength[idx] = objectMaxLength[idx] >= columnValue.length ? objectMaxLength[idx] : columnValue.length
                })
        })

        const wscols = objectMaxLength.map((w: number) => ({ width: w == undefined ? 10 : w + 2 }))
        worksheet["!cols"] = wscols;

    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
    }

}